module.exports={
"label.account.name":`Voornaam`,
"label.account.copy":`Kopieer de willekeurige verificatiecode in de email, en klik op de link om het wachtwoord in de volgende stap te wijzigen.`,
"label.account.phone.length":`Het mobiele nummer moet ten minste 7 tekens bevatten`,
"label.account.Start.trial":`GET STRTED`,
"label.account.employees1000":`1000  werknemers`,
"label.account.other.Ways":`Andere aanmeldingsmethoden`,
"label.account.emailsync.button.cancel":`Annuleren`,
"label.account.rule":`Bestaat uit 816 cijfers en letters.`,
"label.account.password.update.success":`Wachtwoord succesvol gewijzigd.`,
"label.account.describe":`CloudCC richt zich op de digitale personalisatie, analytische intelligentie en wereldwijde connectiviteit, en wordt ondemand aangepast samen met de bedrijfsontwikkeling van ondernemingen.`,
"label.account.enter.Emailbox":`Voer uw emailadres in.`,
"label.account.password":`Wachtwoord`,
"label.account.problem":`Inloggen mislukt.`,
"label.account.Complete.reset":`Email. Voer de verificatiecode in om de wachtwoordreset te voltooien.`,
"label.account.crm.used":`CRM gebruikt door beursgenoteerde ondernemingen`,
"label.account.report.loading":``,
"label.account.notcustomer":`Geen rekening?`,
"label.account.employees501":`5011000 werknemers`,
"label.account.title.operationManager":`Operationeel Manager`,
"label.account.Preset":`Vooraf ingestelde hulpmiddelen voor projectbeheer`,
"label.account.company":`Bedrijf`,
"label.account.come":`Welkom bij Cloudcc Customer Success Platform. Ons nieuwe bliksemplatform biedt u de snelste en meest complete oplossing, zodat u alles kunt doen met uw klant in het middelpunt.  `,
"label.account.input.password":`Voer uw wachtwoord in.`,
"label.account.emailobject.emaildetail.button.back":`Terug`,
"label.account.title.itManager":`IT Manager`,
"label.account.Modified.successfully":`Wachtwoord succesvol gewijzigd. Klik nogmaals op login om terug te keren naar de login pagina.`,
"label.account.only11":`Voer ten minste 7 cijfers in.`,
"label.account.signin":`Inloggen`,
"label.account.register":`Registreer om`,
"label.account.adopt":`Verificatie geslaagd!`,
"label.account.title.hrManager":`Marketing/HR Manager`,
"label.account.input.usernames":`Voer uw gebruikersnaam in.`,
"label.account.only.Numbers":`Telefoonnummer kan alleen cijfers invoeren.`,
"label.account.title.salesManager":`Verkoopmanager`,
"label.account.email":`Email`,
"label.account.click":`Klik op`,
"label.account.and":`en`,
"label.account.mobile":`Telefoon`,
"label.account.Privacy.policy":`Privacybeleid`,
"label.account.password.isnull":`Wachtwoord kan niet leeg zijn.`,
"label.account.employees101":`101500 werknemers`,
"label.account.remember.name":`Onthoud gebruikersnaam`,
"label.account.Select.country":`Selecteer uw land/regio.`,
"label.account.distinguish":`Het wachtwoord is hoofdlettergevoelig.`,
"label.account.fillMobile":`Voer uw telefoonnummer in.`,
"label.account.Preconfigured":`Vooraf ingestelde rapporten en dashboards`,
"label.account.Select.job":`Kies uw positie.`,
"label.account.employees21":`21100 werknemers`,
"label.account.username":`Gebruikersnaam`,
"label.account.Submit":`Indienen`,
"label.account.confirm":`Bevestiging van de rekening`,
"label.account.contact":`Neem contact met ons op`,
"label.account.country":`Land`,
"label.account.username.isnull":`De gebruikersnaam kan niet leeg zijn.`,
"label.account.forget.password":`Wachtwoord vergeten?`,
"label.account.Loading":`Laden`,
"label.account.goon":`Ga door`,
"label.account.Register.trial":`Meld u aan voor een gratis proefperiode`,
"label.account.title.other":`Andere`,
"label.account.surname":`Achternaam`,
"label.account.Fail":`Verificatie mislukt, probeer het opnieuw.`,
"label.account.Terms.service":`Voorwaarden van de Dienst`,
"label.account.Select.staff":`Selecteer het aantal werknemers.`,
"label.account.username.enter":`Vul uw achternaam in.`,
"label.account.trial":`Gratis proefversie`,
"label.account.employees1":`120 werknemers`,
"label.account.Quick.registration":`of snel toegang`,
"label.account.tabpage.ok":`Bevestigen`,
"label.account.normal.reregister":`Opnieuw inloggen`,
"label.account.batchadd.newbatchpage.save.em":`Email formaat is onjuist.`,
"label.account..user.title":`Functie`,
"label.account.No.credit.card.required":`Geen creditcard nodig.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Vooraf laden van steekproefgegevens`,
"label.account.title.hobbies":`Hobby's`,
"label.account.platform":`Nieuw intelligent, mobiel en digitaal CRMplatform`,
"label.account.sent":`Email verzonden`,
"label.account.Confirm.password":`Bevestig uw wachtwoord.`,
"label.account.fill":`Vul alles in.`,
"label.account.user.resetpw":`Reset Wachtwoord`,
"label.account.Spell":`Sleep de schuifregelaar om de puzzel te voltooien.`,
"label.account.identifying.code":`Voer de verificatiecode in.`,
"label.account.staff":`Werknemers`,
"label.account.welcome":`Welkom bij CloudCC`,
"label.account.online.guidance":`Online advies en instructie`,
"label.account.login.CloudCC":`Inloggen op CloudCC`,
"label.account.Company.name":`Voer uw bedrijfsnaam in.`,
"label.account.title.generalManager":`Algemeen Directeur`,
"label.account.enter.user":`Om uw wachtwoord opnieuw in te stellen, voert u uw CloudCCgebruikersnaam in.`,
"label.account.sent.code":`Verificatie code is verzonden naar`,
"label.account.pw.error.text":`De twee wachtwoorden die u invoerde, kwamen niet overeen.`,
"label.account.title.customerManager":`Manager Klantenservice`,
"label.account.label.captcha":`Verificatie Code`,
"label.account.Trial.days":`Meld u aan voor een gratis proefperiode van 30 dagen`,
"label.account.please.enter.name":`Voer uw voornaam in.`,
"label.account.inspect":`Als u de bevestigingsmail met de verificatiecode niet ontvangt, controleer dan de spam.`,
"label.account.User.rule":`De gebruikersnaam is in de vorm van een emailadres.`,
"label.account.go.Login":`Heb je al een account?`,
"label.account.Reading.consent":`Ik ga akkoord met de`,
"label.account.Change.Password2":`Wijzig Wachtwoord`,
"label.account.Loginby":`Inloggen door`,
"label.account.apply.partner":`CloudCCpartner worden`,
"label.account.MobileLogin":`Mobiel`,
"label.account.Registration":``,
"label.account.Italy":`Italië`,
"label.account.view.details":`Bekijk detail`,
"label.account.accept":`Gelieve de Servicevoorwaarden en het Privacybeleid te lezen en te aanvaarden`,
"label.account.China.Mainland":`Chinees vasteland`,
"label.account.NewPassword":`Voer het nieuwe wachtwoord in`,
"label.account.Mobile.not.empty":`Het mobiele nummer kan niet leeg zijn`,
"label.account.Please.select":`Kies alstublieft`,
"label.account.market.introduce":`Uniform platform van marketingkanalen helpt u meer leads binnen te halen`,
"label.account.IOS":`Scan om iOS app te downloaden`,
"label.account.failsend":`Mislukt te verzenden`,
"label.account.UpperLowerlAphanumeric.character":`Hoofdletters, kleine letters, cijfers en symbolen zijn vereist`,
"label.account.New.Password1":`Nieuw wachtwoord`,
"label.account.succSend":`Er is een email naar u verzonden. Kopieer de verificatiecode in de email en stel het wachtwoord opnieuw in.`,
"label.account.Account.mobile":`Rekening of gsmnummer`,
"label.account.Login.consent":`Door in te loggen, gaat u akkoord met`,
"label.account.fillAreaCode":`Voer het netnummer in`,
"label.account.active.login":``,
"label.account.jp":`Japan`,
"label.account.partner.success1":`U heeft zich succesvol geregistreerd als CloudCC partner.`,
"label.account.Reset":`Reset`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Voer het oude wachtwoord in`,
"label.account.Germany":`Duitsland`,
"label.account.title.content":`Meld u aan voor CloudCC | 30 dagen gratis proefversie  Probeer nu!`,
"label.account.Taiwan":`Taiwan, China`,
"label.account.China.Station":`China`,
"label.account.sale.introduce":`Verkoopautomatisering helpt u meer deals binnen te halen`,
"label.account.Back":`Vorige stap`,
"label.account.market":`Marketing wolk`,
"label.account.section.company":`Bedrijf Details`,
"label.account.check.email":`onze account executive zal contact met u op een tijdje, kijk dan op tijd uw email`,
"label.account.characters":`5 tekens`,
"label.account.International":`Internationaal`,
"label.account.britain":`Verenigd Koninkrijk`,
"label.account.reg.fail.msg":`Aanmelden mislukt. Probeer het later nog eens.`,
"label.account.success.reg":`u heeft zich succesvol geregistreerd`,
"label.account.company.url":`Website`,
"label.account.CannotBeEmpty":`Kan niet leeg zijn`,
"label.account.phone.note":``,
"label.account.second":`{0} seconden later`,
"label.account.Change.Password":`Wijzig uw wachtwoord`,
"label.account.Netherlands":`Nederland`,
"label.account.pleaseAgree":`Gelieve de Servicevoorwaarden en het Privacybeleid te aanvaarden en ermee in te stemmen`,
"label.account.copyright":`CloudCC Inc. Alle rechten voorbehouden`,
"label.account.letter":`1 brief`,
"label.account.remember":`Nu weet ik het weer.`,
"label.account.accountNum":`Rekening`,
"label.account.code.not.empty":`De captcha kan niet leeg zijn`,
"label.account.Spain":`Spanje`,
"label.account.Alphanumeric":`Een mix van letters en cijfers is vereist`,
"label.account.obtain":`Verwerven`,
"label.account.company.txt":`Voeg uw naam toe, als u geen geregistreerde bedrijfseenheid hebt`,
"label.account.con.browser":``,
"label.account.Android":`Scannen om Android app te downloaden`,
"label.account.title":`CloudCC Gratis Proefversie  CloudCC.com`,
"label.account.partner.success2":`We zullen over een tijdje contact met je opnemen.`,
"label.account.Macao":`Macau, China`,
"label.account.Brazil":`Brazilië`,
"label.account.service.introduce":`Naadloos serviceproces verbetert de effectiviteit van de reactie`,
"label.account.codeFailure":`Ongeldige verificatiecode`,
"label.account.AccountLogin":`Rekening`,
"label.account.pswComplexReqRules":`De minimale lengte van het wachtwoord is {0}, en {1}`,
"label.account.UpperLowerlAphanumeric":`Hoofdletters, kleine letters en cijfers zijn vereist.`,
"label.account.active.username":``,
"label.account.rememberPassword":`Wachtwoord onthouden`,
"label.account.HongKong":`Hon Kong, China`,
"label.account.oldPass":`Oud wachtwoord`,
"label.account.Data.storage":`Uw gegevens worden opgeslagen in het {0} datacentrum.`,
"label.account.number":`1 nummer`,
"label.account.Poland":`Polen`,
"label.account.service":`Dienst Cloud`,
"label.account.Incorrect.format":`Onjuist formaat of nummer, gelieve opnieuw in te voeren`,
"label.account.remember.status":`Herinner mij`,
"label.account.sale":`Verkoop cloud`,
"label.account.No.account.binding":`Geen account is gebonden. Neem contact op met de beheerder`,
"label.account.Russia":`Rusland`,
"label.account.France":`Frankrijk`,
"label.account.partner.content":`CloudCC is een wereldwijd bedrijf dat nu partners werft om oplossingen in verschillende sectoren te verkennen. Kom en doe mee met ons affiliate programma!`,
"label.account.reg.phone.already":`Deze organisatie bestaat al voor deze telefoon. Log in of gebruik een andere telefoon om u aan te melden.`,
"label.account.Confirm":`Bevestig nieuw wachtwoord`,
"label.account.VerificationLogin":`Verifieer Code`,
"label.account.USA":`Verenigde Staten`,
"label.account.mobile.num":`Voer het mobiele nummer in`,
"label.account.MinLength":`De minimale lengte van het wachtwoord is`,
"label.account.change.your.password":`Verander het wachtwoord nu met het oog op de veiligheid`,
"label.account.new.password":`Voer een nieuw wachtwoord in voor {0}. Zorg ervoor dat u ten minste:`,
"label.account.website.enter":`Voer het officiële website adres in`,
"label.account.pswComplexIsZero":``
}